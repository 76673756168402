import {Component, Inject, OnInit, Optional} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService, Article, ArticleAsset, AssetUploadInfo } from '../API.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-article-dialog-box',
  templateUrl: './article-dialog-box.component.html',
  styleUrls: ['./article-dialog-box.component.scss']
})
export class ArticleDialogBoxComponent implements OnInit{

  action: string;
  article: Article;
  // todo: Bilder-Upload
  files: any = [];

  formName: any;
  formDescription: any;
  formMeasurements: any;
  formWeight: any;
  formAmount: any;

  showArticleAttributes: any = localStorage.getItem('showArticleAttributes');

  constructor(
    public dialogRef: MatDialogRef<ArticleDialogBoxComponent>,
    private api: APIService,
    private http: HttpClient,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
    this.article = data.article;
    this.action = data.action;
  }

  ngOnInit() {
    if (this.showArticleAttributes == 'true') {
      this.formName = new FormControl('', [Validators.required]);
      this.formDescription = new FormControl('', [Validators.required]);
      this.formMeasurements = new FormControl('', [Validators.required]);
      this.formWeight = new FormControl('', [Validators.required]);
      this.formAmount = new FormControl('', [Validators.required]);
    }
    else {
      this.formName = new FormControl('', [Validators.required]);
      this.formDescription = new FormControl('', [Validators.required]);
      this.formMeasurements = new FormControl('');
      this.formWeight = new FormControl('');
      this.formAmount = new FormControl('', [Validators.required]);
    }
  }

    doAction() {
    this.dialogRef.close({ event: this.action, data: this.article });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel', data: this.article });
  }

  // todo: Bilder-Upload
  async uploadFile(event: Event) {
    const fileList = (event.target as HTMLInputElement).files;

    if (fileList) {
      console.log('uploading...');
      console.log(fileList);
      // tslint:disable-next-line: prefer-for-of no - Iterator on FileList
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        console.log(file);
        // this.newImageEvent.srcElement?.files[0]?.name contains the filename
        const originalFilename = file.name;
        // Replace spaces with underscores
        const modifiedFilename = originalFilename?.replace(/\s+/g, '_');

        // add articel asset to article database and get upload url
        const uploadMeta = await this.api.AddArticleAsset(this.article.customerId!, this.article.id!, modifiedFilename, file.type);
        console.log(`Created asset ${modifiedFilename} with id ${uploadMeta.id}`);

        // upload asset to S3 with pre-signed URL
        await this.http.put(uploadMeta.uploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        }).toPromise();
        console.log(`Uploaded asset with id ${uploadMeta.id}`);

        if (!this.article.assets) {
          this.article.assets = [];
        }
        // add data eagerly for UI before next reload
        this.article.assets.push({
          __typename: 'ArticleAsset',
          id: uploadMeta.id,
          mediaType: file.type,
          name: file.name,
          url: uploadMeta.assetUrl,
        });
      }
    }
  }


  async deleteAttachment(assetId: string | undefined, index: number) {
    if (!assetId) {
      return;
    }
    console.log(assetId);
    await this.api.RemoveArticleAsset(this.article.customerId!, this.article.id!, assetId!);
    this.article.assets?.splice(index, 1);
  }

}
