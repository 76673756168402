import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetOpenOrdersQuery } from '../API.service';

@Component({
  selector: 'app-order-remove-dialog-box',
  templateUrl: './order-remove-dialog-box.component.html',
  styleUrls: ['./order-remove-dialog-box.component.scss']
})
export class OrderRemoveDialogBoxComponent {

  order: GetOpenOrdersQuery;

  constructor(
    public dialogRef: MatDialogRef<OrderRemoveDialogBoxComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
    this.order = data.order;
  }

  doAction() {
    this.dialogRef.close({ event: 'löschen', data: this.order });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel', data: this.order });
  }


}
