<div id="main" role="main">

  <h1>{{ 'admincustomer.kundenverwaltung' | transloco }}</h1>
  <i>{{ 'admincustomer.adminbereich' | transloco }}</i>


  <div class="table-container">
    <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
    <div class="mat-elevation-z8">

<!--      <table mat-table [dataSource]="CustomerList"> -->
        <table mat-table [dataSource]="CustomerList">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{ 'global.name' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" [routerLink]="['/admin-customer-detail', row.id]"> {{row.name}} </td><!-- Ruft admin-customer-detail auf und gibt ID mit -->
        </ng-container>

        <!-- Adresse Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> {{ 'global.adresse' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" [routerLink]="['/admin-customer-detail', row.id]"><!-- Ruft admin-customer-detail auf und gibt ID mit -->
            {{row.address?.street}}
            <span *ngIf="row.address?.zip || row.address?.city">, </span>{{row.address?.zip}} {{row.address?.city}}
            <span *ngIf="row.address?.country">, </span> {{row.address?.country}}
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Administration </th>
          <td mat-cell *matCellDef="let customer" class="action-links">
            <a (click)="openDialog('bearbeiten',customer)">{{ 'global.edit' | transloco }}</a> |
            <a (click)="openDialog('löschen',customer)">{{ 'global.delete' | transloco }}</a>
          </td>
        </ng-container>

        <!--<tr mat-header-row *matHeaderRowDef="displayedColumnsCustomerList"></tr> Header auskommentiert-->
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCustomerList;"></tr>
      </table>
    </div>
    <div class="action-row">
        <button (click)="openDialog('anlegen')" color="accent" mat-raised-button  attr.aria-label="{{ 'admincustomer.newCustomer' | transloco }}"><mat-icon>add</mat-icon></button>
    </div>
  </div>

</div>
