<h1 mat-dialog-title *ngIf="action == 'anlegen'">{{ 'userdialog.titleNew' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'bearbeiten'">{{ 'userdialog.titleEdit' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'löschen'">{{ 'userdialog.titleDelete' | transloco }}</h1>


<div mat-dialog-content class="form" *ngIf="action != 'löschen'; else elseTemplate">
  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'userdialog.name' | transloco }}" matInput [(ngModel)]="local_data.displayName">
  </mat-form-field>
  <mat-form-field class="dataFieldMax">
    <input placeholder="{{ 'userdialog.mail' | transloco }}" matInput [(ngModel)]="local_data.email" [disabled]="action == 'bearbeiten'">
  </mat-form-field>
  <mat-form-field class="dataField">
    <input placeholder="{{ 'userdialog.phone' | transloco }}" matInput [(ngModel)]="local_data.phone">
  </mat-form-field>
  <mat-form-field class="dataField">
    <input placeholder="{{ 'userdialog.position' | transloco }}" matInput [(ngModel)]="local_data.position">
  </mat-form-field>
</div>

<ng-template #elseTemplate>
  <p>{{ 'userdialog.user1' | transloco }} <b>{{local_data.name}}</b> {{ 'userdialog.user2' | transloco }}</p>
</ng-template>

<div mat-dialog-actions class="actions">
  <button mat-button (click)="doAction()" mat-flat-button color="accent">{{ 'userdialog.ok' | transloco }}</button>
  <button mat-button (click)="closeDialog()">{{ 'userdialog.cancel' | transloco }}</button>
</div>
