<div id="main" role="main">
  <h1>{{ 'global.bestellungen' | transloco }}</h1>
  <i>{{ 'adminorderslist.titel' | transloco }}</i>

  <h3>{{ 'adminorderslist.open' | transloco }}</h3>

  <div class="table-container">

    <mat-form-field *ngIf="OpenOrdersList">
      <input matInput (keyup)="applyFilterOpen($event)" placeholder="{{ 'global.search' | transloco }}">

    </mat-form-field>

    <div class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
      <table *ngIf="OpenOrdersList" mat-table multiTemplateDataRows [dataSource]="OpenOrdersList">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Von Column -->
        <ng-container matColumnDef="von">
          <th mat-header-cell *matHeaderCellDef> {{ 'adminorderslist.from' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.deliveryDate | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <!-- Bis Column -->
        <ng-container matColumnDef="bis">
          <th mat-header-cell *matHeaderCellDef> {{ 'adminorderslist.to' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.pickupDate | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <!-- Veranstaltung Column -->
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef> {{ 'adminorderslist.event' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.eventName}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> {{ 'adminorderslist.status' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.state}} </td>
        </ng-container>

        <!-- OrderPrintout Column -->
        <ng-container matColumnDef="print">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> {{ 'adminorderslist.deliverynote' | transloco }} </th>
          <!-- TODO mgress: Lieferschein erst ab Shipped anzeigen. Bei Ordered brauch ich einen Dialog um eine Notiz und die Tracking Info zu erfassen. -->
          <td mat-cell class="tableIcon" *matCellDef="let orders">
            <mat-icon class="clickable" (click)="deleteOrder(orders)" color="warn">delete</mat-icon>
            <mat-icon class="clickable" (click)="editOrder(orders)">edit</mat-icon>
            <mat-icon class="clickable" *ngIf="orders.state == 'Ordered'" [routerLink]="['/order-deliverynote', orders.id]" [queryParams]="{customerId: orders.customerId}">assignment</mat-icon>
            <mat-icon class="clickable icon-flipped" *ngIf="orders.state == 'Shipped'" [routerLink]="['/order-deliverynote', orders.id]" [queryParams]="{customerId: orders.customerId}">assignment_return</mat-icon>
            <mat-icon class="clickable" *ngIf="orders.state == 'Returned'" [routerLink]="['/order-deliverynote', orders.id]" [queryParams]="{customerId: orders.customerId}">assignment_return</mat-icon>         <!-- todo: State implementieren -->
            <!-- <mat-icon *ngIf="orders.state == 'Late'" color="warn">assignment_late</mat-icon>
            <mat-icon *ngIf="orders.state == 'Paid'">assignment_turned_in</mat-icon>  -->
          </td><!-- Ruft admin-customer-detail auf und gibt ID mit -->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsOpenOrdersList"></tr>
        <tr mat-row *matRowDef="let order; columns: displayedColumnsOpenOrdersList;"
            class="order-row">
        </tr>

        <!--<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>-->

      </table>
    </div>
  </div>
</div>
