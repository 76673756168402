import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APIService, GetOpenOrdersQuery } from '../API.service';
import { NewOrderDialogBoxComponent } from '../new-order-dialog-box/new-order-dialog-box.component';
import { OrderRemoveDialogBoxComponent } from '../order-remove-dialog-box/order-remove-dialog-box.component';


@Component({
  selector: 'app-admin-orders-list',
  templateUrl: './admin-orders-list.component.html',
  styleUrls: ['./admin-orders-list.component.scss']
})
export class AdminOrdersListComponent implements OnInit {

  constructor(private api: APIService, public dialog: MatDialog, private router: Router) { }
  loadingBar = false;
  displayedColumnsOpenOrdersList: string[] = ['event', 'von', 'bis', 'state', 'print'];
  //OpenOrdersList: MatTableDataSource<GetOpenOrdersQuery>;
  OpenOrdersList: MatTableDataSource<GetOpenOrdersQuery> = new MatTableDataSource<GetOpenOrdersQuery>([]);


  ngOnInit() {
    this.loadingBar = true;
    console.log('getting open orders...');

    this.api.GetOpenOrders()
      .then(orders => {
        this.OpenOrdersList = new MatTableDataSource(orders);
        console.log('OrderList:');
        console.table(orders);
        this.loadingBar = false;
      })
      .catch((error) => {
        console.log('could not get open orders');
      });
  }

  applyFilterOpen(event: Event): void {
    const filterValue = (event.target as HTMLInputElement)?.value?.trim().toLowerCase();
  }

  editOrder(order: GetOpenOrdersQuery) {
    const obj = {
      _id: order.id,
      event: order.eventName,
      from: order.deliveryDate,
      to: order.pickupDate,
      blockStart: order.blockStart,
      blockEnd: order.blockEnd,
      deliveryWindow: order.deliveryWindow,
      pickupWindow: order.pickupWindow,
      address: {
        contactName: order.deliveryAddress?.contactName,
        contactNote: order.deliveryAddress?.contactNote,
        street: order.deliveryAddress?.street,
        zip: order.deliveryAddress?.zip,
        city: order.deliveryAddress?.city,
        company: order.deliveryAddress?.company,
        email: order.deliveryAddress?.email,
        phone: order.deliveryAddress?.phone,
        country: order.deliveryAddress?.country,
      },
      orderAddress: {
        contactName: order.orderAddress?.contactName,
        contactNote: order.orderAddress?.contactNote,
        street: order.orderAddress?.street,
        zip: order.orderAddress?.zip,
        city: order.orderAddress?.city,
        company: order.orderAddress?.company,
        email: order.orderAddress?.email,
        phone: order.orderAddress?.phone,
        country: order.orderAddress?.country,
      },
      articles: undefined,
      state: order.state,
    };
    const dialogRef = this.dialog.open(NewOrderDialogBoxComponent, {
      data: { ...obj, action: 'adminEdit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const orderdata = result.data;
      delete orderdata.action;

      if (result.event === 'adminEdit') {
        this.api.ChangeOrder(orderdata.id, {
          eventName: orderdata.event,
          orderAddress: {
            company: orderdata.orderAddress.company,
            contactName: orderdata.orderAddress.contactName,
            contactNote: orderdata.orderAddress.contactNote,
            email: orderdata.orderAddress.email,
            phone: orderdata.orderAddress.phone,
            street: orderdata.orderAddress.street,
            zip: orderdata.orderAddress.zip,
            city: orderdata.orderAddress.city,
          },
          deliveryAddress: {
            company: orderdata.address.company,
            contactName: orderdata.address.contactName,
            contactNote: orderdata.address.contactNote,
            email: orderdata.address.email,
            phone: orderdata.address.phone,
            street: orderdata.address.street,
            zip: orderdata.address.zip,
            city: orderdata.address.city,
          },
          deliveryWindow: orderdata.deliveryWindow,
          pickupWindow: orderdata.pickupWindow,
          deliveryDate: orderdata.from,
          pickupDate: orderdata.to,
          blockStart: orderdata.blockStart,
          blockEnd: orderdata.blockEnd,
        }).then((res) => {
          console.log(res);
          this.ngOnInit();
        });
      }
    });
  }

  deleteOrder(order: GetOpenOrdersQuery) {
    const dialogRef = this.dialog.open(OrderRemoveDialogBoxComponent, {
      data: { order },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const data = result.data;
      delete data.action;

      if (result.event === 'löschen') {
        this.api.RemoveOrder(order.id).then(() => {
          this.ngOnInit();
        });
      }
    });

  }

}
