import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(public router: Router) { }

  async canActivate(): Promise<boolean> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    this.router.navigate(['login']);
    return false;
  }
}
