<div id="main" role="main">
    <h1>{{ 'global.bestellungen' | transloco }}</h1>
    <i>{{ 'adminordersreport.titel' | transloco }}</i>

    <h3>{{ 'adminordersreport.orders' | transloco }}</h3>

    <div class="table-container">
        <div class="mat-elevation-z8">
            <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
            <table *ngIf="SummaryList" mat-table multiTemplateDataRows [dataSource]="SummaryList" class="report-table">

                <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                <!-- Jahr Column -->
                <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef> {{ 'adminordersreport.year' | transloco }} </th>
                    <td mat-cell *matCellDef="let summary"> {{summary.year}} </td>
                </ng-container>

                <!-- Monat Column -->
                <ng-container matColumnDef="month">
                    <th mat-header-cell *matHeaderCellDef> {{ 'adminordersreport.month' | transloco }} </th>
                    <td mat-cell *matCellDef="let summary"> {{summary.month}} </td>
                </ng-container>

                <!-- Kunde Column -->
                <ng-container matColumnDef="customer">
                    <th mat-header-cell *matHeaderCellDef> {{ 'adminordersreport.customer' | transloco }} </th>
                    <td mat-cell *matCellDef="let summary" [routerLink]="['/admin-orders-report', summary.year, summary.month, summary.customerId]"> {{summary.customerName}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let order; columns: displayedColumns;" class="order-row">
                </tr>
                <!--<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>-->
            </table>
        </div>
    </div>
</div>
