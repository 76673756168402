<div id="main" role="main">
  <i>{{ 'admincustomerdetail.titel' | transloco }}</i>

  <div class="action-row">
    <button (click)="openCustomerDialog('bearbeiten', CustomerDetails)" color="primary" mat-raised-button  attr.aria-label="{{ 'admincustomerdetail.editData' | transloco }}"><mat-icon>edit</mat-icon></button>
  </div>
  <h1>{{CustomerDetails?.name}}</h1>

  <h2>{{ 'admincustomerdetail.anschrift' | transloco }}</h2>
  <p>{{CustomerDetails?.address?.street}}</p>
  <p>{{CustomerDetails?.address?.zip}} {{CustomerDetails?.address?.city}}</p>
  <p>{{CustomerDetails?.address?.country}}</p>

  <h2>{{ 'admincustomerdetail.hinweis' | transloco }}</h2>
  <p>{{CustomerDetails?.orderNote}}</p>

  <h2>{{ 'admincustomerdetail.artikel' | transloco }}</h2>
  <div class="table-container">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="ArticleList" class="article-table">

        <!-- Image Column -->
        <ng-container matColumnDef="images">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="imageContainer">
            <div class="imageBox">
              <ng-container *ngFor="let file of row?.assets; let i= index">
                <img class="uploadedImage" [src]="file.url"/>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container *ngIf="showArticleAttributes == 'true'">

          <ng-container matColumnDef="name"><th mat-header-cell *matHeaderCellDef>
              <ng-container>{{ 'admincustomerdetail.bezeichnung' | transloco }}</ng-container>
            </th>
            <td mat-cell class="clickable" *matCellDef="let row" (click)="openArticleDialog('bearbeiten',row)"> {{row.name}} </td>
          </ng-container>
          <!-- Maße Column -->
          <ng-container matColumnDef="measurements">
            <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.masse' | transloco }} </th>
            <td mat-cell class="clickable" *matCellDef="let row" (click)="openArticleDialog('bearbeiten',row)"> {{row.measurements}} </td>
          </ng-container>
          <!-- Gewicht Column -->
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.gewicht' | transloco }} </th>
            <td mat-cell class="clickable" *matCellDef="let row" (click)="openArticleDialog('bearbeiten',row)"> {{row.weight}} </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showArticleAttributes == 'false'">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container>{{ 'admincustomerdetail.bezeichnungmasse' | transloco }}</ng-container>
            </th>
            <td mat-cell class="clickable" *matCellDef="let row" (click)="openArticleDialog('bearbeiten',row)"> {{row.name}} </td>
          </ng-container>
        </ng-container>

        <!-- Stückzahl Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.stueck' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" (click)="openArticleDialog('bearbeiten',row)"> {{row.amount}} </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let articles" class="action-links">
            <a *ngIf="!articles.assets?.length" (click)="openArticleDialog('upload',articles)"><mat-icon>add_photo_alternate</mat-icon></a>
            <a (click)="openArticleDialog('bearbeiten',articles)"><mat-icon>edit</mat-icon></a>
            <a (click)="openRemoveArticleDialog(articles)"><mat-icon color="warn">delete</mat-icon></a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsArticleList"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsArticleList;"></tr>
      </table>
    </div>
    <div class="action-row">
      <button (click)="openArticleDialog('anlegen', undefined)" color="accent" mat-raised-button  attr.aria-label="{{ 'admincustomerdetail.neuerArtikel' | transloco }}"><mat-icon>add</mat-icon></button>
    </div>
  </div>

  <h2>{{ 'admincustomerdetail.orders' | transloco }}</h2>

  <div class="table-container">

    <div class="mat-elevation-z8">
      <table mat-table multiTemplateDataRows [dataSource]="OrderList">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Von Column -->
        <ng-container matColumnDef="von">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.from' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.deliveryDate | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <!-- Bis Column -->
        <ng-container matColumnDef="bis">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.to' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.pickupDate | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <!-- Veranstaltung Column -->
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.event' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.eventName}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.status' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.state}} </td>
        </ng-container>

        <!-- OrderPrintout Column -->
        <ng-container matColumnDef="print">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> {{ 'admincustomerdetail.details' | transloco }} </th>
          <td mat-cell class="clickable tableIcon" *matCellDef="let orders" [routerLink]="['/order-deliverynote', orders.id]" [queryParams]="{customerId: customerID}" style="text-align: center;">
            <mat-icon *ngIf="orders.state == 'Ordered' || orders.state == 'Created'" >assignment</mat-icon>
            <mat-icon *ngIf="orders.state == 'Shipped'" class="icon-flipped">assignment_return</mat-icon>
            <mat-icon *ngIf="orders.state == 'Returned'">assignment_return</mat-icon>         <!-- todo: State implementieren -->
            <!-- <mat-icon *ngIf="orders.state == 'Late'" color="warn">assignment_late</mat-icon>
            <mat-icon *ngIf="orders.state == 'Paid'">assignment_turned_in</mat-icon>  -->
          </td><!-- Ruft admin-customer-detail auf und gibt ID mit -->

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsOrderList"></tr>
        <tr mat-row *matRowDef="let order; columns: displayedColumnsOrderList;" class="order-row"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <h2>{{ 'admincustomerdetail.benutzer' | transloco }}</h2>

  <div class="table-container">

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="UserList">
        <!-- Name Column -->
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.name' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" (click)="openUserDialog('bearbeiten',row)"> {{row.displayName}} </td>
        </ng-container>
        <!-- E-Mail Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.email' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" (click)="openUserDialog('bearbeiten',row)"> {{row.email}} </td>
        </ng-container>
        <!-- Telefon Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.phone' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" (click)="openUserDialog('bearbeiten',row)"> {{row.phone}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> {{ 'admincustomerdetail.position' | transloco }} </th>
          <td mat-cell class="clickable" *matCellDef="let row" (click)="openUserDialog('bearbeiten',row)"> {{row.position}} </td>
        </ng-container>
        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let customer" class="action-links">
            <a (click)="openUserDialog('bearbeiten',customer)"><mat-icon>edit</mat-icon></a>
            <a (click)="openUserDialog('löschen',customer)"><mat-icon color="warn">delete</mat-icon></a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsUserList"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsUserList;"></tr>
      </table>
    </div>
    <div class="action-row">
      <button (click)="openUserDialog('anlegen', undefined)" color="accent" mat-raised-button  attr.aria-label="{{ 'admincustomerdetail.newUser' | transloco }}"><mat-icon>add</mat-icon></button>
    </div>
  </div>

</div>
