import Auth from '@aws-amplify/auth';

const awsmobile = {
  aws_appsync_graphqlEndpoint: 'https://api.imagingyou-lieferservice.de/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'null',
  aws_user_pools_id: 'eu-central-1_6g4DF4Loq',
  aws_user_pools_web_client_id: '233b3tr6rrcik5da9qa48m1lti',
  aws_cognito_region: 'eu-central-1',
  graphql_headers: async () => {
    return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
  },
};

export default awsmobile;
