<div id="main" role="main">

  <div class="no-print">
    <h1>{{ 'deliverynote.title' | transloco }} - {{OrderDetail?.eventName}}</h1>

    <button (click)="print()" mat-fab color="accent" attr.aria-label="{{ 'deliverynote.print' | transloco }}" class="no-print"
      *ngIf="OrderDetail?.state == 'Shipped' || OrderDetail?.state == 'Returned'"><mat-icon>print</mat-icon></button>

    <p><b>{{OrderDetail?.deliveryDate | date:'dd.MM.yyyy'}}</b> - <b>{{OrderDetail?.pickupDate | date:'dd.MM.yyyy'}}</b></p>

    <p>{{ 'deliverynote.number' | transloco }} <b>{{OrderDetail?.id}}</b></p>

    <p>{{ 'deliverynote.status' | transloco }} <b>{{OrderDetail?.state}}</b></p>
    <p>{{ 'global.orderer' | transloco }} <b>{{OrderDetail?.orderer}}</b></p>
    <hr>

    <h3>{{ 'deliverynote.deliverydata' | transloco }}</h3>
    <p>
      {{ 'deliverynote.deliverywindow' | transloco }} <b>{{OrderDetail?.deliveryWindow}}</b><br>
      {{ 'deliverynote.pickupwindow' | transloco }} <b>{{OrderDetail?.pickupWindow}}</b>
    </p>
    <div class="order-detail">
      <table>
        <tr>
          <td>
            <h4>{{ 'deliverynote.address' | transloco }}</h4>
            <p>{{OrderDetail?.deliveryAddress?.company}}</p>
            <p>{{OrderDetail?.deliveryAddress?.street}}</p>
            <p>{{OrderDetail?.deliveryAddress?.zip}} {{OrderDetail?.deliveryAddress?.city}}</p>
            <p>{{OrderDetail?.deliveryAddress?.country}}</p>
          </td>
          <td>
            <h4>{{ 'deliverynote.orderaddress' | transloco }}</h4>
            <p>{{OrderDetail?.orderAddress?.company}}</p>
            <p>{{OrderDetail?.orderAddress?.street}}</p>
            <p>{{OrderDetail?.orderAddress?.zip}} {{OrderDetail?.orderAddress?.city}}</p>
            <p>{{OrderDetail?.orderAddress?.country}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h4>{{ 'deliverynote.contact' | transloco }}</h4>
            <p>{{OrderDetail?.deliveryAddress?.contactName}}</p>
            <p>{{OrderDetail?.deliveryAddress?.phone}}</p>
            <p>{{OrderDetail?.deliveryAddress?.contactNote}}</p>
          </td>
          <td>
            <h4>{{ 'deliverynote.ordercontact' | transloco }}</h4>
            <p>{{OrderDetail?.orderAddress?.contactName}}</p>
            <p>{{OrderDetail?.orderAddress?.phone}}</p>
            <p>{{OrderDetail?.orderAddress?.contactNote}}</p>
          </td>
        </tr>
      </table>
    </div>

    <hr>

    <h3>{{ 'deliverynote.shipping' | transloco }}</h3>
    <div *ngIf="admin">
      <div class="form" *ngIf="OrderDetail?.state != 'Shipped' && OrderDetail?.state != 'Returned'">
        <mat-form-field class="dataField">
          <input placeholder="{{ 'deliverynote.tracking' | transloco }}" matInput #shippingTracking>
        </mat-form-field>
        <mat-form-field class="dataField">
          <input matInput [(ngModel)]="shipDate" [matDatepicker]="pickerShippingdate"
            placeholder="{{ 'deliverynote.shippingdate' | transloco }}" disabled>
          <mat-datepicker-toggle matSuffix [for]="pickerShippingdate"></mat-datepicker-toggle>
          <mat-datepicker disabled="false" #pickerShippingdate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <textarea rows="5" placeholder="{{ 'deliverynote.shippinghint' | transloco }}" matInput #shippingInfo></textarea>
        </mat-form-field>
        <div class="dataFieldMax">
          <button class="no-print"
            (click)="shipOrder(OrderDetail!.customerId!, OrderDetail!.id!, shippingTracking.value, shippingInfo.value, shipDate)" mat-btn
            color="accent" mat-raised-button attr.aria-label="{{ 'deliverynote.prep' | transloco }}" style="margin: 2rem 0;"> <mat-icon
              class="icon-flipped">assignment_return</mat-icon> {{ 'deliverynote.prep' | transloco }}</button>
        </div>
      </div>
    </div>
    <div *ngIf="OrderDetail?.state == 'Shipped' || OrderDetail?.state == 'Returned' || customer">
      <p>{{ 'deliverynote.tracking' | transloco }}: <b>{{OrderDetail?.deliveryInfo?.shippingId}}</b></p>
      <p>{{ 'deliverynote.date' | transloco }} <b>{{OrderDetail?.deliveryInfo?.shippingDate | date:'dd.MM.yyyy'}}</b></p>
      <p>{{ 'deliverynote.hint' | transloco }} <b>{{OrderDetail?.deliveryInfo?.note}}</b></p>
    </div>

    <hr>

    <h3>{{ 'deliverynote.article' | transloco }}</h3>
    <p *ngIf="!OrderDetail?.articles?.length">{{ 'deliverynote.noarticles' | transloco }}</p>
    <div class="table-container">
      <table *ngIf="OrderDetail?.articles?.length">
        <thead>
          <tr>
            <th style="width: 200px;">{{ 'deliverynote.articlename' | transloco }}</th>
            <th style="width: 50px; text-align: center;">{{ 'deliverynote.amount' | transloco }}</th>
            <th style="width: 50px;">{{ 'deliverynote.packed' | transloco }}</th>
            <th>{{ 'deliverynote.addon' | transloco }}</th>
            <th style="width: 50px;">{{ 'deliverynote.back' | transloco }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let article of OrderDetail?.articles">
            <tr *ngIf="article && article.amount! > 0">
              <td style="width: 200px;"><b>{{ article.name }}</b><br /><span>{{article.id}}</span></td>
              <td style="width: 50px; text-align: center;"><b>{{ article.amount }}</b></td>
              <td style="width: 50px;"></td>
              <td></td>
              <td style="width: 50px;"></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <button (click)="print()" mat-btn color="accent" mat-raised-button attr.aria-label="{{ 'deliverynote.print' | transloco }}"
      class="no-print" style="margin: 2rem 0;"
      *ngIf="OrderDetail?.state == 'Shipped' || OrderDetail?.state == 'Returned'"><mat-icon>print</mat-icon> {{ 'deliverynote.print' |
      transloco }}</button>

  </div>


  <table class="print-me">
    <thead>
      <tr>
        <td>
          <div class="header-space">&nbsp;</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="deliverynote-container"> <!--print-me-->
            <div class="deliverynote-dummycontainer"></div>
            <img src="assets/img/imaging-you-blue.svg" class="deliverynote-logo">
            <div class="deliverynote-addressCustomer">
              <p>{{OrderDetail?.deliveryAddress?.company}}<br>
                {{OrderDetail?.deliveryAddress?.contactName}}<br>
                {{OrderDetail?.deliveryAddress?.street}}<br>
                {{OrderDetail?.deliveryAddress?.zip}} {{OrderDetail?.deliveryAddress?.city}}<br>
                {{OrderDetail?.deliveryAddress?.country}}</p>
              <p><b>{{ 'deliverynote.phone' | transloco }} {{OrderDetail?.deliveryAddress?.phone}}</b><br>
                {{ 'deliverynote.hint' | transloco }} {{OrderDetail?.deliveryAddress?.contactNote}}</p>
            </div>
            <div class="deliverynote-dummycontainer"></div>
            <div class="deliverynote-dummycontainer"></div>
            <div class="deliverynote-infos">
              <table>
                <tr>
                  <td>{{ 'deliverynote.date' | transloco }} </td>
                  <td>{{OrderDetail?.deliveryInfo?.shippingDate | date:'dd.MM.yyyy'}}</td>
                </tr>
                <!--Lieferart :-->
                <tr>
                  <td>{{ 'deliverynote.most' | transloco }} </td>
                  <td>{{OrderDetail?.orderAddress?.contactName}}</td>
                </tr>
                <tr>
                  <td>{{ 'deliverynote.ordernumber' | transloco }} </td>
                  <td>{{OrderDetail?.id}}</td>
                </tr>
              </table>
            </div>
            <div class="deliverynote-articles">
              <h2>{{ 'deliverynote.title' | transloco }}</h2>
              <p *ngIf="!OrderDetail?.articles?.length">{{ 'deliverynote.noarticles' | transloco }}</p>
              <div class="deliverynote-articles-table">
                <table *ngIf="OrderDetail?.articles?.length">
                  <thead>
                    <tr>
                      <th style="width: 90px;">{{ 'deliverynote.amount' | transloco }}</th>
                      <th>{{ 'deliverynote.description' | transloco }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let article of OrderDetail?.articles">
                      <tr *ngIf="article && article.amount && article.amount > 0">
                        <td style="width: 90px;">{{ article.amount }}</td>
                        <td>{{ article.name }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="deliverynote-signature">
              <p>{{ 'deliverynote.allok' | transloco }}</p>
              <div class="deliverynote-signature-line">{{ 'deliverynote.signature' | transloco }}</div>
              <p>{{ 'deliverynote.fax' | transloco }}</p>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <div class="footer-space">&nbsp;</div>
        </td>
      </tr>
    </tfoot>
  </table>
  <div class="deliverynote-footer print-me">
    <div>
      <div>www.imagingyou.de</div>
      <table class="deliverynote-contact">
        <tr>
          <td>Imaging You</td>
          <td>by Oliver Gern</td>
        </tr>
        <tr>
          <td>Odentaler Weg 65</td>
          <td>Ust-Id Nr. DE815042050</td>
        </tr>
        <tr>
          <td>42659 Solingen</td>
          <td>Sparkasse Hilden Ratingen Velbert</td>
        </tr>
        <tr>
          <td>T +49 (0)212-64 56 03-0</td>
          <td>SWIFT BIC WELADED1VEL</td>
        </tr>
        <tr>
          <td>T +49 (0)212-64 56 03-11</td>
          <td>IBAN DE004 3345 0000 0042 1102 47</td>
        </tr>
      </table>
    </div>
  </div>
</div>
