import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService, Article } from '../API.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-article-remove-dialog-box',
  templateUrl: './article-remove-dialog-box.component.html',
  styleUrls: ['./article-remove-dialog-box.component.scss']
})
export class ArticleRemoveDialogBoxComponent {

  article: Article;

  constructor(
    public dialogRef: MatDialogRef<ArticleRemoveDialogBoxComponent>,
    private api: APIService,
    private http: HttpClient,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
    this.article = data.article;
  }

  doAction() {
    this.dialogRef.close({ event: 'löschen', data: this.article });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel', data: this.article });
  }

}
