import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Order } from '../API.service';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-user-dialog-box',
  templateUrl: './new-order-dialog-box.component.html',
  styleUrls: ['./new-order-dialog-box.component.scss'],
})
export class NewOrderDialogBoxComponent {

  action: string;
  local_data: any;

  deliveryTimeHH: string;
  deliveryTimeMM: string;
  deliveryTimeAMPM: string;
  deliveryTimeCombined: string;

  pickupTimeHH: string;
  pickupTimeMM: string;
  pickupTimeAMPM: string;
  pickupTimeCombined: string;

  minDate: any;

  OrderAddressMandatory: any = localStorage.getItem('orderAddressMandatory');


  constructor(
    public dialogRef: MatDialogRef<NewOrderDialogBoxComponent>,
    private adapter: DateAdapter<any>,

    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Order) {
    console.log(data);
    this.local_data = { ...data };
    this.action = this.local_data.action;
    console.log('OrderAddressMandatory: ' + this.OrderAddressMandatory);

    this.deliveryTimeCombined = (data.deliveryWindow && data.deliveryWindow?.length > 0) ? data.deliveryWindow : '10:00 AM';
    this.deliveryTimeHH = this.deliveryTimeCombined.substring(0, 2);
    this.deliveryTimeMM = this.deliveryTimeCombined.substring(3, 5);
    this.deliveryTimeAMPM = this.deliveryTimeCombined.substring(6, 8);

    this.pickupTimeCombined = (data.pickupWindow && data.pickupWindow.length > 0) ? data.pickupWindow : '05:00 PM';
    this.pickupTimeHH = this.pickupTimeCombined.substring(0, 2);
    this.pickupTimeMM = this.pickupTimeCombined.substring(3, 5);
    this.pickupTimeAMPM = this.pickupTimeCombined.substring(6, 8);

    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 4);

    // Adjust the datepicker calendar to start from the minimum date
    this.adapter.setLocale('en'); // Change 'en' to your desired locale

  }

  onDeliveryTimeChange() {
    this.deliveryTimeCombined = '' + this.deliveryTimeHH + ':' + this.deliveryTimeMM + ' ' + this.deliveryTimeAMPM;
  }
  onPickupTimeChange() {
    this.pickupTimeCombined = '' + this.pickupTimeHH + ':' + this.pickupTimeMM + ' ' + this.pickupTimeAMPM;
  }

  doAction() {
    this.local_data.to = new DatePipe('en').transform(this.local_data.to, 'yyyy-MM-dd');
    this.local_data.from = new DatePipe('en').transform(this.local_data.from, 'yyyy-MM-dd');
    this.local_data.blockStart = new DatePipe('en').transform(this.local_data.blockStart, 'yyyy-MM-dd');
    this.local_data.blockEnd = new DatePipe('en').transform(this.local_data.blockEnd, 'yyyy-MM-dd');
    this.local_data.deliveryWindow = this.deliveryTimeCombined;
    this.local_data.pickupWindow = this.pickupTimeCombined;
    if (this.OrderAddressMandatory == 'false') {
      this.local_data.orderAddress.company = this.local_data.address.company;
      this.local_data.orderAddress.street = this.local_data.address.street;
      this.local_data.orderAddress.zip = this.local_data.address.zip;
      this.local_data.orderAddress.city = this.local_data.address.city;
      this.local_data.orderAddress.email = this.local_data.address.email;
      this.local_data.orderAddress.phone = this.local_data.address.phone;
      this.local_data.orderAddress.contactName = this.local_data.address.contactName;
      this.local_data.orderAddress.contactNote = this.local_data.address.contactNote;
    }

    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
