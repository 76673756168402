import { Component, OnInit, ViewChild } from '@angular/core';
import { APIService, Customer, Order } from '../API.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NewOrderDialogBoxComponent } from '../new-order-dialog-box/new-order-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Auth from "@aws-amplify/auth";

@Component({
  selector: 'app-customer-orders',
  templateUrl: './customer-orders.component.html',
  styleUrls: ['./customer-orders.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomerOrdersComponent implements OnInit {

  constructor(private api: APIService, public dialog: MatDialog, private router: Router) { }

  displayedColumnsOrderList: string[] = ['event', 'von', 'bis', 'articles', 'state', 'print'];
  expandedElement!: Order[] | null;
  loadingBar = false;
  approver: boolean = false;
  orderApproved: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  OrderList?: MatTableDataSource<Order>;
  CustomerInformation?: Customer;

  OrderAddressMandatory: any = localStorage.getItem('orderAddressMandatory');

  openDialog(action: string, obj?: any) {
    if (action === 'anlegen') {
      obj = {
        id: '',
        event: '',
        from: '',
        to: '',
        pickupWindow: '',
        deliveryWindow: '',
        address: {
          contactName: '',
          contactNote: '',
          street: '',
          zip: '',
          city: '',
          company: '',
          country: '',
          email: '',
          phone: '',
        },
        orderAddress: {
          contactName: '',
          contactNote: '',
          street: '',
          zip: '',
          city: '',
          company: '',
          country: '',
          email: '',
          phone: '',
        },
        articles: [],
        state: 'Pending',
      };
    }
    const dialogRef = this.dialog.open(NewOrderDialogBoxComponent, {
      data: { ...obj, action }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      console.log(result);

      if (result.event === 'anlegen') {
        const data = result.data;
        delete data.action;
        console.log(data);
        this.api.CreateOrder({
          eventName: data.event,
          deliveryDate: data.from,
          pickupDate: data.to,
          deliveryWindow: data.deliveryWindow,
          pickupWindow: data.pickupWindow,
          deliveryAddress: {
            contactName: data.address.contactName,
            contactNote: data.address.contactNote,
            company: data.address.company,
            street: data.address.street,
            zip: data.address.zip,
            city: data.address.city,
            country: data.address.country,
            email: data.address.email,
            phone: data.address.phone,
          },
          orderAddress: {
            contactName: data.orderAddress.contactName,
            contactNote: data.orderAddress.contactNote,
            company: data.orderAddress.company,
            street: data.orderAddress.street,
            zip: data.orderAddress.zip,
            city: data.orderAddress.city,
            country: data.orderAddress.country,
            email: data.orderAddress.email,
            phone: data.orderAddress.phone,
          },
        }).then((res) => {
          console.log(res);
          this.router.navigate(['customer-orders', res.id]);
        });
      } else if (result.event === 'löschen') {
        // todo: DeleteOrder
        // this.deleteRowData(data);
        console.log('deleted');
      }
    });
  }


  async ngOnInit() {
    this.loadingBar = true;

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user && user.signInUserSession.idToken.payload['cognito:groups']
        && user.signInUserSession.idToken.payload['cognito:groups'].indexOf('approver') >= 0) {
        this.approver = true;
        console.log('Approver');
      }
    } catch (e) {
      if (e === 'not authenticated') {
        this.router.navigate(['/login']);
      }
      console.log(e);
    }
    console.log('getting orders...');
    this.api.GetMyOrders()
      .then(orders => {
        this.OrderList = new MatTableDataSource((orders as any) as Order[]);
        this.OrderList.paginator = this.paginator;
        console.log(this.OrderList);
        this.loadingBar = false;
      })
      .catch((error) => {
        console.log('could not get orders');
      });

    this.api.GetMyCustomer()
      .then(customer => {
        this.CustomerInformation = customer as Customer;
        console.log(this.CustomerInformation);
      })
      .catch((error) => {
        console.log('could not get customer information');
      });
  }

  applyFilterOpen(event: Event): void {
    if (this.OrderList) {
      this.OrderList.filter = (event.target as HTMLInputElement)?.value?.trim().toLowerCase();
    }
  }

  orderApproval(orderID: string): void {
    console.log('approve Order-ID: ' + orderID);
    this.api.ConfirmOrder(orderID)
      .then(response => {
        console.log(response);
        this.orderApproved = true;
        setTimeout(() => {
          this.ngOnInit();
        }, 3000);
      })
      .catch((error) => {
        console.log('could not approve order');
      });
  }

  openDetail(id: string): void {
    console.log('opening: ' + id);
    this.router.navigate(['customer-orders', id]);
  }

}
