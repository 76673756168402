import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { PubSub } from '@aws-amplify/pubsub';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import awsconfig from './aws-exports';

PubSub.configure(awsconfig);
API.configure(awsconfig);
Auth.configure(awsconfig);

Sentry.init({
  dsn: "https://09d187117f676797bf77d8d7dd3b0f27@o4505193076228096.ingest.sentry.io/4506077915643904",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: ['imagingyou-lieferservice.de', 'taimos.cloud', 'stripe.com', 'amazonaws.com'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: 'imagingyou-' + environment.stage,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
