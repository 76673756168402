/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { API, graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";

export type SaveCustomerInput = {
  name: string;
  address?: AddressInput | null;
  orderNote?: string | null;
  features?: CustomerFeaturesInput | null;
};

export type AddressInput = {
  street: string;
  zip: string;
  city: string;
  country?: string | null;
};

export type CustomerFeaturesInput = {
  showArticleAttributes?: boolean | null;
  orderAddressMandatory?: boolean | null;
  orderApprovalProcess?: boolean | null;
};

export type Customer = {
  __typename: "Customer";
  id: string;
  name: string;
  orderNote?: string | null;
  address?: Address | null;
  features?: CustomerFeatures | null;
  logoMediaType?: string | null;
  logoUrl?: string | null;
};

export type Address = {
  __typename: "Address";
  street: string;
  zip: string;
  city: string;
  country?: string | null;
};

export type CustomerFeatures = {
  __typename: "CustomerFeatures";
  showArticleAttributes?: boolean | null;
  orderAddressMandatory?: boolean | null;
  orderApprovalProcess?: boolean | null;
};

export type AssetUploadInfo = {
  __typename: "AssetUploadInfo";
  id: string;
  uploadUrl: string;
  assetUrl: string;
};

export type ArticleInput = {
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
};

export type Article = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<ArticleAsset | null> | null;
};

export type ArticleAsset = {
  __typename: "ArticleAsset";
  id: string;
  mediaType: string;
  name: string;
  url: string;
};

export type DeliveryInfoInput = {
  shippingId: string;
  shippingDate: string;
  note?: string | null;
};

export type Order = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: ExtendedAddress | null;
  deliveryAddress?: ExtendedAddress | null;
  invoiceAddress?: ExtendedAddress | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<Article | null> | null;
  deliveryInfo?: DeliveryInfo | null;
  blockStart: string;
  blockEnd: string;
};

export type ExtendedAddress = {
  __typename: "ExtendedAddress";
  company: string;
  info?: string | null;
  contactName: string;
  contactNote?: string | null;
  street: string;
  zip: string;
  city: string;
  country?: string | null;
  email?: string | null;
  phone?: string | null;
  vatId?: string | null;
};

export type DeliveryInfo = {
  __typename: "DeliveryInfo";
  shippingId: string;
  shippingDate: string;
  note?: string | null;
};

export type CreateOrderInput = {
  eventName: string;
  deliveryWindow: string;
  pickupWindow: string;
  deliveryDate: string;
  pickupDate: string;
  deliveryAddress: ExtendedAddressInput;
  orderAddress?: ExtendedAddressInput | null;
};

export type ExtendedAddressInput = {
  company: string;
  info?: string | null;
  contactName: string;
  contactNote?: string | null;
  street: string;
  zip: string;
  city: string;
  country?: string | null;
  email: string;
  phone?: string | null;
  vatId?: string | null;
};

export type SendOrderInput = {
  orderAddress: ExtendedAddressInput;
  deliveryWindow: string;
  pickupWindow: string;
  articles?: Array<OrderArticleInput | null> | null;
  eventName?: string | null;
  deliveryAddress?: ExtendedAddressInput | null;
};

export type OrderArticleInput = {
  id: string;
  amount?: number | null;
};

export type ChangeOrderInput = {
  eventName?: string | null;
  deliveryDate: string;
  pickupDate: string;
  deliveryWindow: string;
  pickupWindow: string;
  orderAddress: ExtendedAddressInput;
  deliveryAddress?: ExtendedAddressInput | null;
  blockStart: string;
  blockEnd: string;
};

export type User = {
  __typename: "User";
  sub: string;
  name: string;
  enabled: boolean;
  status: string;
  displayName?: string | null;
  email?: string | null;
  customer?: string | null;
  position?: string | null;
  phone?: string | null;
};

export type OrderSummary = {
  __typename: "OrderSummary";
  year: string;
  month: string;
  customerId: string;
  customerName: string;
  orderCount: number;
};

export type AddCustomerMutation = {
  __typename: "Customer";
  id: string;
  name: string;
  orderNote?: string | null;
  address?: {
    __typename: "Address";
    street: string;
    zip: string;
    city: string;
    country?: string | null;
  } | null;
  features?: {
    __typename: "CustomerFeatures";
    showArticleAttributes?: boolean | null;
    orderAddressMandatory?: boolean | null;
    orderApprovalProcess?: boolean | null;
  } | null;
  logoMediaType?: string | null;
  logoUrl?: string | null;
};

export type SaveCustomerMutation = {
  __typename: "Customer";
  id: string;
  name: string;
  orderNote?: string | null;
  address?: {
    __typename: "Address";
    street: string;
    zip: string;
    city: string;
    country?: string | null;
  } | null;
  features?: {
    __typename: "CustomerFeatures";
    showArticleAttributes?: boolean | null;
    orderAddressMandatory?: boolean | null;
    orderApprovalProcess?: boolean | null;
  } | null;
  logoMediaType?: string | null;
  logoUrl?: string | null;
};

export type AddCustomerLogoMutation = {
  __typename: "AssetUploadInfo";
  id: string;
  uploadUrl: string;
  assetUrl: string;
};

export type AddArticleMutation = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<{
    __typename: "ArticleAsset";
    id: string;
    mediaType: string;
    name: string;
    url: string;
  } | null> | null;
};

export type SaveArticleMutation = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<{
    __typename: "ArticleAsset";
    id: string;
    mediaType: string;
    name: string;
    url: string;
  } | null> | null;
};

export type AddArticleAssetMutation = {
  __typename: "AssetUploadInfo";
  id: string;
  uploadUrl: string;
  assetUrl: string;
};

export type ShipOrderMutation = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type CreateOrderMutation = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type SendOrderMutation = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type ConfirmOrderMutation = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type ChangeOrderMutation = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type GetCustomersQuery = {
  __typename: "Customer";
  id: string;
  name: string;
  orderNote?: string | null;
  address?: {
    __typename: "Address";
    street: string;
    zip: string;
    city: string;
    country?: string | null;
  } | null;
  features?: {
    __typename: "CustomerFeatures";
    showArticleAttributes?: boolean | null;
    orderAddressMandatory?: boolean | null;
    orderApprovalProcess?: boolean | null;
  } | null;
  logoMediaType?: string | null;
  logoUrl?: string | null;
};

export type GetCustomerQuery = {
  __typename: "Customer";
  id: string;
  name: string;
  orderNote?: string | null;
  address?: {
    __typename: "Address";
    street: string;
    zip: string;
    city: string;
    country?: string | null;
  } | null;
  features?: {
    __typename: "CustomerFeatures";
    showArticleAttributes?: boolean | null;
    orderAddressMandatory?: boolean | null;
    orderApprovalProcess?: boolean | null;
  } | null;
  logoMediaType?: string | null;
  logoUrl?: string | null;
};

export type GetMyCustomerQuery = {
  __typename: "Customer";
  id: string;
  name: string;
  orderNote?: string | null;
  address?: {
    __typename: "Address";
    street: string;
    zip: string;
    city: string;
    country?: string | null;
  } | null;
  features?: {
    __typename: "CustomerFeatures";
    showArticleAttributes?: boolean | null;
    orderAddressMandatory?: boolean | null;
    orderApprovalProcess?: boolean | null;
  } | null;
  logoMediaType?: string | null;
  logoUrl?: string | null;
};

export type GetAdminUsersQuery = {
  __typename: "User";
  sub: string;
  name: string;
  enabled: boolean;
  status: string;
  displayName?: string | null;
  email?: string | null;
  customer?: string | null;
  position?: string | null;
  phone?: string | null;
};

export type GetUsersByCustomerQuery = {
  __typename: "User";
  sub: string;
  name: string;
  enabled: boolean;
  status: string;
  displayName?: string | null;
  email?: string | null;
  customer?: string | null;
  position?: string | null;
  phone?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  sub: string;
  name: string;
  enabled: boolean;
  status: string;
  displayName?: string | null;
  email?: string | null;
  customer?: string | null;
  position?: string | null;
  phone?: string | null;
};

export type GetArticlesByCustomerQuery = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<{
    __typename: "ArticleAsset";
    id: string;
    mediaType: string;
    name: string;
    url: string;
  } | null> | null;
};

export type GetArticleQuery = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<{
    __typename: "ArticleAsset";
    id: string;
    mediaType: string;
    name: string;
    url: string;
  } | null> | null;
};

export type GetMyArticlesQuery = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<{
    __typename: "ArticleAsset";
    id: string;
    mediaType: string;
    name: string;
    url: string;
  } | null> | null;
};

export type GetOrdersByCustomerQuery = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type GetOrdersByCustomerAndMonthQuery = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type GetOpenOrdersQuery = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type GetOrderQuery = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type GetMyOrdersQuery = {
  __typename: "Order";
  id: string;
  customerId: string;
  state: string;
  eventName: string;
  orderer?: string | null;
  orderAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  invoiceAddress?: {
    __typename: "ExtendedAddress";
    company: string;
    info?: string | null;
    contactName: string;
    contactNote?: string | null;
    street: string;
    zip: string;
    city: string;
    country?: string | null;
    email?: string | null;
    phone?: string | null;
    vatId?: string | null;
  } | null;
  deliveryWindow?: string | null;
  pickupWindow?: string | null;
  deliveryDate: string;
  pickupDate: string;
  articles?: Array<{
    __typename: "Article";
    id: string;
    customerId: string;
    name: string;
    measurements?: string | null;
    weight?: string | null;
    amount?: number | null;
    description?: string | null;
    assets?: Array<{
      __typename: "ArticleAsset";
      id: string;
      mediaType: string;
      name: string;
      url: string;
    } | null> | null;
  } | null> | null;
  deliveryInfo?: {
    __typename: "DeliveryInfo";
    shippingId: string;
    shippingDate: string;
    note?: string | null;
  } | null;
  blockStart: string;
  blockEnd: string;
};

export type GetAvailableArticlesForOrderQuery = {
  __typename: "Article";
  id: string;
  customerId: string;
  name: string;
  measurements?: string | null;
  weight?: string | null;
  amount?: number | null;
  description?: string | null;
  assets?: Array<{
    __typename: "ArticleAsset";
    id: string;
    mediaType: string;
    name: string;
    url: string;
  } | null> | null;
};

export type GetOrderSummariesQuery = {
  __typename: "OrderSummary";
  year: string;
  month: string;
  customerId: string;
  customerName: string;
  orderCount: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async AddCustomer(customer: SaveCustomerInput): Promise<AddCustomerMutation> {
    const statement = `mutation AddCustomer($customer: SaveCustomerInput!) {
        addCustomer(customer: $customer) {
          __typename
          id
          name
          orderNote
          address {
            __typename
            street
            zip
            city
            country
          }
          features {
            __typename
            showArticleAttributes
            orderAddressMandatory
            orderApprovalProcess
          }
          logoMediaType
          logoUrl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customer
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddCustomerMutation>response.data.addCustomer;
  }
  async SaveCustomer(
    id: string,
    customer: SaveCustomerInput
  ): Promise<SaveCustomerMutation> {
    const statement = `mutation SaveCustomer($id: ID!, $customer: SaveCustomerInput!) {
        saveCustomer(id: $id, customer: $customer) {
          __typename
          id
          name
          orderNote
          address {
            __typename
            street
            zip
            city
            country
          }
          features {
            __typename
            showArticleAttributes
            orderAddressMandatory
            orderApprovalProcess
          }
          logoMediaType
          logoUrl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
      customer
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SaveCustomerMutation>response.data.saveCustomer;
  }
  async RemoveCustomer(id: string): Promise<boolean | null> {
    const statement = `mutation RemoveCustomer($id: ID!) {
        removeCustomer(id: $id)
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.removeCustomer;
  }
  async AddCustomerLogo(
    id: string,
    mediaType?: string
  ): Promise<AddCustomerLogoMutation> {
    const statement = `mutation AddCustomerLogo($id: ID!, $mediaType: String) {
        addCustomerLogo(id: $id, mediaType: $mediaType) {
          __typename
          id
          uploadUrl
          assetUrl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    if (mediaType) {
      gqlAPIServiceArguments.mediaType = mediaType;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddCustomerLogoMutation>response.data.addCustomerLogo;
  }
  async AddUser(
    customerId: string,
    email: string,
    displayName: string,
    position: string,
    phone: string
  ): Promise<boolean | null> {
    const statement = `mutation AddUser($customerId: ID!, $email: String!, $displayName: String!, $position: String!, $phone: String!) {
        addUser(
          customerId: $customerId
          email: $email
          displayName: $displayName
          position: $position
          phone: $phone
        )
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      email,
      displayName,
      position,
      phone
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.addUser;
  }
  async ModifyUser(
    customerId: string,
    email: string,
    displayName: string,
    position: string,
    phone: string
  ): Promise<boolean | null> {
    const statement = `mutation ModifyUser($customerId: ID!, $email: String!, $displayName: String!, $position: String!, $phone: String!) {
        modifyUser(
          customerId: $customerId
          email: $email
          displayName: $displayName
          position: $position
          phone: $phone
        )
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      email,
      displayName,
      position,
      phone
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.modifyUser;
  }
  async RemoveUser(customerId: string, email: string): Promise<boolean | null> {
    const statement = `mutation RemoveUser($customerId: ID!, $email: String!) {
        removeUser(customerId: $customerId, email: $email)
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      email
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.removeUser;
  }
  async AddAdminUser(
    email: string,
    displayName: string
  ): Promise<boolean | null> {
    const statement = `mutation AddAdminUser($email: String!, $displayName: String!) {
        addAdminUser(email: $email, displayName: $displayName)
      }`;
    const gqlAPIServiceArguments: any = {
      email,
      displayName
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.addAdminUser;
  }
  async AddArticle(
    customerId: string,
    article: ArticleInput
  ): Promise<AddArticleMutation> {
    const statement = `mutation AddArticle($customerId: ID!, $article: ArticleInput!) {
        addArticle(customerId: $customerId, article: $article) {
          __typename
          id
          customerId
          name
          measurements
          weight
          amount
          description
          assets {
            __typename
            id
            mediaType
            name
            url
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      article
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddArticleMutation>response.data.addArticle;
  }
  async SaveArticle(
    customerId: string,
    id: string,
    article: ArticleInput
  ): Promise<SaveArticleMutation> {
    const statement = `mutation SaveArticle($customerId: ID!, $id: ID!, $article: ArticleInput!) {
        saveArticle(customerId: $customerId, id: $id, article: $article) {
          __typename
          id
          customerId
          name
          measurements
          weight
          amount
          description
          assets {
            __typename
            id
            mediaType
            name
            url
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      id,
      article
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SaveArticleMutation>response.data.saveArticle;
  }
  async RemoveArticle(customerId: string, id: string): Promise<boolean | null> {
    const statement = `mutation RemoveArticle($customerId: ID!, $id: ID!) {
        removeArticle(customerId: $customerId, id: $id)
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.removeArticle;
  }
  async AddArticleAsset(
    customerId: string,
    articleId: string,
    name: string,
    mediaType?: string
  ): Promise<AddArticleAssetMutation> {
    const statement = `mutation AddArticleAsset($customerId: ID!, $articleId: ID!, $name: String!, $mediaType: String) {
        addArticleAsset(
          customerId: $customerId
          articleId: $articleId
          name: $name
          mediaType: $mediaType
        ) {
          __typename
          id
          uploadUrl
          assetUrl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      articleId,
      name
    };
    if (mediaType) {
      gqlAPIServiceArguments.mediaType = mediaType;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddArticleAssetMutation>response.data.addArticleAsset;
  }
  async RemoveArticleAsset(
    customerId: string,
    articleId: string,
    assetId: string
  ): Promise<boolean | null> {
    const statement = `mutation RemoveArticleAsset($customerId: ID!, $articleId: ID!, $assetId: ID!) {
        removeArticleAsset(
          customerId: $customerId
          articleId: $articleId
          assetId: $assetId
        )
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      articleId,
      assetId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.removeArticleAsset;
  }
  async ShipOrder(
    customerId: string,
    id: string,
    data: DeliveryInfoInput
  ): Promise<ShipOrderMutation> {
    const statement = `mutation ShipOrder($customerId: ID!, $id: ID!, $data: DeliveryInfoInput!) {
        shipOrder(customerId: $customerId, id: $id, data: $data) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      id,
      data
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ShipOrderMutation>response.data.shipOrder;
  }
  async CreateOrder(data: CreateOrderInput): Promise<CreateOrderMutation> {
    const statement = `mutation CreateOrder($data: CreateOrderInput!) {
        createOrder(data: $data) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      data
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderMutation>response.data.createOrder;
  }
  async SendOrder(
    id: string,
    data: SendOrderInput
  ): Promise<SendOrderMutation> {
    const statement = `mutation SendOrder($id: ID!, $data: SendOrderInput!) {
        sendOrder(id: $id, data: $data) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
      data
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SendOrderMutation>response.data.sendOrder;
  }
  async ConfirmOrder(id: string): Promise<ConfirmOrderMutation> {
    const statement = `mutation ConfirmOrder($id: ID!) {
        confirmOrder(id: $id) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ConfirmOrderMutation>response.data.confirmOrder;
  }
  async ChangeOrder(
    id: string,
    data: ChangeOrderInput
  ): Promise<ChangeOrderMutation> {
    const statement = `mutation ChangeOrder($id: ID!, $data: ChangeOrderInput!) {
        changeOrder(id: $id, data: $data) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
      data
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ChangeOrderMutation>response.data.changeOrder;
  }
  async RemoveOrder(id: string): Promise<boolean | null> {
    const statement = `mutation RemoveOrder($id: ID!) {
        removeOrder(id: $id)
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <boolean | null>response.data.removeOrder;
  }
  async GetCustomers(): Promise<Array<GetCustomersQuery>> {
    const statement = `query GetCustomers {
        getCustomers {
          __typename
          id
          name
          orderNote
          address {
            __typename
            street
            zip
            city
            country
          }
          features {
            __typename
            showArticleAttributes
            orderAddressMandatory
            orderApprovalProcess
          }
          logoMediaType
          logoUrl
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetCustomersQuery>>response.data.getCustomers;
  }
  async GetCustomer(id: string): Promise<GetCustomerQuery> {
    const statement = `query GetCustomer($id: ID!) {
        getCustomer(id: $id) {
          __typename
          id
          name
          orderNote
          address {
            __typename
            street
            zip
            city
            country
          }
          features {
            __typename
            showArticleAttributes
            orderAddressMandatory
            orderApprovalProcess
          }
          logoMediaType
          logoUrl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerQuery>response.data.getCustomer;
  }
  async GetMyCustomer(): Promise<GetMyCustomerQuery> {
    const statement = `query GetMyCustomer {
        getMyCustomer {
          __typename
          id
          name
          orderNote
          address {
            __typename
            street
            zip
            city
            country
          }
          features {
            __typename
            showArticleAttributes
            orderAddressMandatory
            orderApprovalProcess
          }
          logoMediaType
          logoUrl
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <GetMyCustomerQuery>response.data.getMyCustomer;
  }
  async GetAdminUsers(): Promise<Array<GetAdminUsersQuery>> {
    const statement = `query GetAdminUsers {
        getAdminUsers {
          __typename
          sub
          name
          enabled
          status
          displayName
          email
          customer
          position
          phone
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetAdminUsersQuery>>response.data.getAdminUsers;
  }
  async GetUsersByCustomer(
    customerId: string
  ): Promise<Array<GetUsersByCustomerQuery>> {
    const statement = `query GetUsersByCustomer($customerId: ID!) {
        getUsersByCustomer(customerId: $customerId) {
          __typename
          sub
          name
          enabled
          status
          displayName
          email
          customer
          position
          phone
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetUsersByCustomerQuery>>response.data.getUsersByCustomer;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          sub
          name
          enabled
          status
          displayName
          email
          customer
          position
          phone
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async GetArticlesByCustomer(
    customerId: string
  ): Promise<Array<GetArticlesByCustomerQuery>> {
    const statement = `query GetArticlesByCustomer($customerId: ID!) {
        getArticlesByCustomer(customerId: $customerId) {
          __typename
          id
          customerId
          name
          measurements
          weight
          amount
          description
          assets {
            __typename
            id
            mediaType
            name
            url
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetArticlesByCustomerQuery>>(
      response.data.getArticlesByCustomer
    );
  }
  async GetArticle(
    customerId: string,
    articleId: string
  ): Promise<GetArticleQuery> {
    const statement = `query GetArticle($customerId: ID!, $articleId: ID!) {
        getArticle(customerId: $customerId, articleId: $articleId) {
          __typename
          id
          customerId
          name
          measurements
          weight
          amount
          description
          assets {
            __typename
            id
            mediaType
            name
            url
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      articleId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetArticleQuery>response.data.getArticle;
  }
  async GetMyArticles(): Promise<Array<GetMyArticlesQuery>> {
    const statement = `query GetMyArticles {
        getMyArticles {
          __typename
          id
          customerId
          name
          measurements
          weight
          amount
          description
          assets {
            __typename
            id
            mediaType
            name
            url
          }
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetMyArticlesQuery>>response.data.getMyArticles;
  }
  async GetOrdersByCustomer(
    customerId: string
  ): Promise<Array<GetOrdersByCustomerQuery>> {
    const statement = `query GetOrdersByCustomer($customerId: ID!) {
        getOrdersByCustomer(customerId: $customerId) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetOrdersByCustomerQuery>>response.data.getOrdersByCustomer;
  }
  async GetOrdersByCustomerAndMonth(
    customerId: string,
    year: string,
    month: string
  ): Promise<Array<GetOrdersByCustomerAndMonthQuery>> {
    const statement = `query GetOrdersByCustomerAndMonth($customerId: ID!, $year: String!, $month: String!) {
        getOrdersByCustomerAndMonth(customerId: $customerId, year: $year, month: $month) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      customerId,
      year,
      month
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetOrdersByCustomerAndMonthQuery>>(
      response.data.getOrdersByCustomerAndMonth
    );
  }
  async GetOpenOrders(): Promise<Array<GetOpenOrdersQuery>> {
    const statement = `query GetOpenOrders {
        getOpenOrders {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetOpenOrdersQuery>>response.data.getOpenOrders;
  }
  async GetOrder(orderId: string, customerId?: string): Promise<GetOrderQuery> {
    const statement = `query GetOrder($orderId: ID!, $customerId: ID) {
        getOrder(orderId: $orderId, customerId: $customerId) {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const gqlAPIServiceArguments: any = {
      orderId
    };
    if (customerId) {
      gqlAPIServiceArguments.customerId = customerId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderQuery>response.data.getOrder;
  }
  async GetMyOrders(): Promise<Array<GetMyOrdersQuery>> {
    const statement = `query GetMyOrders {
        getMyOrders {
          __typename
          id
          customerId
          state
          eventName
          orderer
          orderAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          invoiceAddress {
            __typename
            company
            info
            contactName
            contactNote
            street
            zip
            city
            country
            email
            phone
            vatId
          }
          deliveryWindow
          pickupWindow
          deliveryDate
          pickupDate
          articles {
            __typename
            id
            customerId
            name
            measurements
            weight
            amount
            description
            assets {
              __typename
              id
              mediaType
              name
              url
            }
          }
          deliveryInfo {
            __typename
            shippingId
            shippingDate
            note
          }
          blockStart
          blockEnd
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetMyOrdersQuery>>response.data.getMyOrders;
  }
  async GetAvailableArticlesForOrder(
    orderId: string
  ): Promise<Array<GetAvailableArticlesForOrderQuery>> {
    const statement = `query GetAvailableArticlesForOrder($orderId: ID!) {
        getAvailableArticlesForOrder(orderId: $orderId) {
          __typename
          id
          customerId
          name
          measurements
          weight
          amount
          description
          assets {
            __typename
            id
            mediaType
            name
            url
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      orderId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetAvailableArticlesForOrderQuery>>(
      response.data.getAvailableArticlesForOrder
    );
  }
  async GetOrderSummaries(): Promise<Array<GetOrderSummariesQuery>> {
    const statement = `query GetOrderSummaries {
        getOrderSummaries {
          __typename
          year
          month
          customerId
          customerName
          orderCount
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetOrderSummariesQuery>>response.data.getOrderSummaries;
  }
}
