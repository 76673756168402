import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})

export class RoleGuardService implements CanActivate {
  constructor(
    public router: Router
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const expectedRole = route.data['expectedRole'];
      if (user && user.signInUserSession.idToken.payload['cognito:groups']
        && user.signInUserSession.idToken.payload['cognito:groups'].indexOf(expectedRole) >= 0) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    this.router.navigate(['login']);
    return false;
  }

}
