<div id="main" role="main">
  <h1>{{ 'adminuser.titel' | transloco }}</h1>

  <h3>{{ 'adminuser.list' | transloco }}</h3>

  <table mat-table [dataSource]="userList" class="mat-elevation-z8">

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{ 'adminuser.id' | transloco }} </th>
      <td mat-cell *matCellDef="let user"> {{user.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'adminuser.name' | transloco }} </th>
      <td mat-cell *matCellDef="let user"> {{user.displayName}} </td>
    </ng-container>

    <!-- E-Mail Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{ 'adminuser.mail' | transloco }} </th>
      <td mat-cell *matCellDef="let user"> {{user.email}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsUserList"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsUserList;"></tr>
  </table>
</div>
