import { Component, OnInit } from '@angular/core';
import { APIService, User } from '../API.service';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit {

  constructor(private api: APIService) { }

  displayedColumnsUserList: string[] = ['name', 'email'];
  userList = [];

  ngOnInit() {
    this.api.GetAdminUsers().then(users => {
      // @ts-expect-error
      this.userList = ((users as any) as User[]);
      console.log(this.userList);
    });
  }


}

