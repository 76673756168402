<h1 mat-dialog-title *ngIf="action == 'anlegen'">{{ 'neworderdialog.neworder' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'bearbeiten'">{{ 'neworderdialog.editorder' | transloco }}</h1>
<h1 mat-dialog-title *ngIf="action == 'löschen'">{{ 'neworderdialog.deleteorder' | transloco }}</h1>

<form name="orderForm" #orderForm="ngForm">
  <div mat-dialog-content class="form" *ngIf="action != 'löschen'; else elseTemplate">
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.event' | transloco }}" name="event" matInput [(ngModel)]="local_data.event" required>
    </mat-form-field>

    <h4 style="width:100%;">{{ 'neworderdialog.delivery' | transloco }}</h4>
<!--
    <mat-form-field class="dataField50">
      <input matInput [(ngModel)]="local_data.deliveryWindow" name="deliveryWindow" [matDatepicker]="pickerDelivery" placeholder="{{ 'neworderdialog.deliverywindow' | transloco }}" disabled>
      <mat-datepicker-toggle matSuffix [for]="pickerDelivery"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #pickerDelivery></mat-datepicker>
    </mat-form-field>
-->

    <mat-form-field class="dataField" *ngIf="action == 'anlegen' || action == 'adminEdit'">
      <input matInput [min]="minDate" [(ngModel)]="local_data.from" name="from" [matDatepicker]="pickerFrom" placeholder="{{ 'neworderdialog.from' | transloco }}" disabled
        required>
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="dataField" *ngIf="action == 'anlegen' || action == 'adminEdit'">
      <input matInput [(ngModel)]="local_data.to" name="to" [matDatepicker]="pickerTo" [min]="local_data.from"
        placeholder="{{ 'neworderdialog.to' | transloco }}" disabled required>
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #pickerTo></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="dataField" *ngIf="action == 'adminEdit'">
      <input matInput [(ngModel)]="local_data.blockStart" name="blockStart" [matDatepicker]="pickerBlockStart" placeholder="{{ 'neworderdialog.blockedfrom' | transloco }}" [max]="local_data.from" disabled
        required>
      <mat-datepicker-toggle matSuffix [for]="pickerBlockStart"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #pickerBlockStart></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="dataField" *ngIf="action == 'adminEdit'">
      <input matInput [(ngModel)]="local_data.blockEnd" name="blockEnd" [matDatepicker]="pickerBlockEnd" [min]="local_data.to"
        placeholder="{{ 'neworderdialog.blockedto' | transloco }}" disabled required>
      <mat-datepicker-toggle matSuffix [for]="pickerBlockEnd"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #pickerBlockEnd></mat-datepicker>
    </mat-form-field>

    <div class="dataFieldTime dataField50">
      <p>{{ 'neworderdialog.deliverywindow' | transloco }}</p>

      <mat-form-field>
        <mat-select [(ngModel)]="deliveryTimeHH" name="deliveryTimeHH" (selectionChange)="onDeliveryTimeChange()">
          <mat-option value="01">01</mat-option>
          <mat-option value="02">02</mat-option>
          <mat-option value="03">03</mat-option>
          <mat-option value="04">04</mat-option>
          <mat-option value="05">05</mat-option>
          <mat-option value="06">06</mat-option>
          <mat-option value="07">07</mat-option>
          <mat-option value="08">08</mat-option>
          <mat-option value="09">09</mat-option>
          <mat-option value="10">10</mat-option>
          <mat-option value="11">11</mat-option>
          <mat-option value="12">12</mat-option>
        </mat-select>
      </mat-form-field>
      <span>:</span>
      <mat-form-field>
        <mat-select [(ngModel)]="deliveryTimeMM" name="deliveryTimeMM" (selectionChange)="onDeliveryTimeChange()">
          <mat-option value="00">00</mat-option>
          <mat-option value="15">15</mat-option>
          <mat-option value="30">30</mat-option>
          <mat-option value="45">45</mat-option>
        </mat-select>
      </mat-form-field>
      <span>&nbsp;</span>
      <mat-form-field>
          <mat-select [(ngModel)]="deliveryTimeAMPM" name="deliveryTimeAMPM" (selectionChange)="onDeliveryTimeChange()">
          <mat-option value="AM">AM</mat-option>
          <mat-option value="PM">PM</mat-option>
        </mat-select>

      </mat-form-field>
    </div>

<!--
    <mat-form-field class="dataField50">
      <input placeholder="{{ 'neworderdialog.pickupwindow' | transloco }}" name="pickupWindow" matInput [(ngModel)]="local_data.pickupWindow">
    </mat-form-field>
-->
    <div class="dataFieldTime dataField50">
      <p>{{ 'neworderdialog.pickupwindow' | transloco }}</p>

      <mat-form-field>
        <mat-select [(ngModel)]="pickupTimeHH" name="pickupTimeHH" (selectionChange)="onPickupTimeChange()">
          <mat-option value="01">01</mat-option>
          <mat-option value="02">02</mat-option>
          <mat-option value="03">03</mat-option>
          <mat-option value="04">04</mat-option>
          <mat-option value="05">05</mat-option>
          <mat-option value="06">06</mat-option>
          <mat-option value="07">07</mat-option>
          <mat-option value="08">08</mat-option>
          <mat-option value="09">09</mat-option>
          <mat-option value="10">10</mat-option>
          <mat-option value="11">11</mat-option>
          <mat-option value="12">12</mat-option>
        </mat-select>
      </mat-form-field>
      <span>:</span>
      <mat-form-field>
        <mat-select [(ngModel)]="pickupTimeMM" name="pickupTimeMM" (selectionChange)="onPickupTimeChange()">
          <mat-option value="00">00</mat-option>
          <mat-option value="15">15</mat-option>
          <mat-option value="30">30</mat-option>
          <mat-option value="45">45</mat-option>
        </mat-select>
      </mat-form-field>
      <span>&nbsp;</span>
      <mat-form-field>
        <mat-select [(ngModel)]="pickupTimeAMPM" name="pickupTimeAMPM" (selectionChange)="onPickupTimeChange()">
          <mat-option value="AM">AM</mat-option>
          <mat-option value="PM">PM</mat-option>
        </mat-select>

      </mat-form-field>
    </div>

    <h4>{{ 'neworderdialog.address' | transloco }}</h4>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.company' | transloco }}" matInput name="addressCompany" [(ngModel)]="local_data.address.company" required>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.street' | transloco }}" name="addressStreet" matInput [(ngModel)]="local_data.address.street"
        required>
    </mat-form-field>
    <mat-form-field class="dataFieldShort">
      <input placeholder="{{ 'neworderdialog.plz' | transloco }}" matInput name="addressZip" [(ngModel)]="local_data.address.zip" required>
    </mat-form-field>
    <mat-form-field class="dataFieldLong">
      <input placeholder="{{ 'neworderdialog.city' | transloco }}" matInput name="addressCity" [(ngModel)]="local_data.address.city" required>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.country' | transloco }}" matInput name="addressCountry" [(ngModel)]="local_data.address.country">
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.mail' | transloco }}" matInput type="email" name="addressEmail" [(ngModel)]="local_data.address.email"
        required>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.phone' | transloco }}" matInput name="addressPhone" [(ngModel)]="local_data.address.phone" required>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <input placeholder="{{ 'neworderdialog.contact' | transloco }}" name="addressContactName" matInput
        [(ngModel)]="local_data.address.contactName" required>
    </mat-form-field>
    <mat-form-field class="dataFieldMax">
      <textarea rows="4" placeholder="{{ 'neworderdialog.contacthint' | transloco }}" matInput name="addressContactNote"
        [(ngModel)]="local_data.address.contactNote"></textarea>
    </mat-form-field>

    <ng-container *ngIf="OrderAddressMandatory == 'true'">
      <h4>{{ 'neworderdialog.orderaddress' | transloco }}</h4>
      <mat-form-field class="dataFieldMax">
        <input placeholder="{{ 'neworderdialog.company' | transloco }}" matInput name="orderAddressCompany" [(ngModel)]="local_data.orderAddress.company" required>
      </mat-form-field>
      <mat-form-field class="dataFieldMax">
        <input placeholder="{{ 'neworderdialog.street' | transloco }}" matInput name="orderAddressStreet"
          [(ngModel)]="local_data.orderAddress.street" required>
      </mat-form-field>
      <mat-form-field class="dataFieldShort">
        <input placeholder="{{ 'neworderdialog.plz' | transloco }}" matInput name="orderAddressZip" [(ngModel)]="local_data.orderAddress.zip" required>
      </mat-form-field>
      <mat-form-field class="dataFieldLong">
        <input placeholder="{{ 'neworderdialog.city' | transloco }}" matInput name="orderAddressCity" [(ngModel)]="local_data.orderAddress.city" required>
      </mat-form-field>
      <mat-form-field class="dataFieldMax">
        <input placeholder="{{ 'neworderdialog.country' | transloco }}" matInput name="orderAddressCountry" [(ngModel)]="local_data.orderAddress.country">
      </mat-form-field>
      <mat-form-field class="dataFieldMax">
        <input placeholder="{{ 'neworderdialog.mail' | transloco }}" matInput type="email" name="orderAddressEmail"
          [(ngModel)]="local_data.orderAddress.email" required>
      </mat-form-field>
      <mat-form-field class="dataFieldMax">
        <input placeholder="{{ 'neworderdialog.phone' | transloco }}" matInput name="orderAddressPhone" [(ngModel)]="local_data.orderAddress.phone">
      </mat-form-field>
      <mat-form-field class="dataFieldMax">
        <input placeholder="{{ 'neworderdialog.contact' | transloco }}" matInput name="orderAddressContactName"
          [(ngModel)]="local_data.orderAddress.contactName" required>
      </mat-form-field>
      <mat-form-field class="dataFieldMax">
        <textarea rows="4" placeholder="{{ 'neworderdialog.contacthint' | transloco }}" matInput name="orderAddressContactNote"
          [(ngModel)]="local_data.orderAddress.contactNote"></textarea>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="OrderAddressMandatory == 'false'">
      <div style="display: none;">
        <h4>{{ 'neworderdialog.orderaddress' | transloco }}</h4>
        <mat-form-field class="dataFieldMax">
          <input placeholder="{{ 'neworderdialog.company' | transloco }}" matInput name="orderAddressCompany" [(ngModel)]="local_data.orderAddress.company">
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <input placeholder="{{ 'neworderdialog.street' | transloco }}" matInput name="orderAddressStreet"
                 [(ngModel)]="local_data.orderAddress.street">
        </mat-form-field>
        <mat-form-field class="dataFieldShort">
          <input placeholder="{{ 'neworderdialog.plz' | transloco }}" matInput name="orderAddressZip" [(ngModel)]="local_data.orderAddress.zip">
        </mat-form-field>
        <mat-form-field class="dataFieldLong">
          <input placeholder="{{ 'neworderdialog.city' | transloco }}" matInput name="orderAddressCity" [(ngModel)]="local_data.orderAddress.city">
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <input placeholder="{{ 'neworderdialog.country' | transloco }}" matInput name="orderAddressCountry" [(ngModel)]="local_data.orderAddress.country">
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <input placeholder="{{ 'neworderdialog.mail' | transloco }}" matInput type="email" name="orderAddressEmail"
                 [(ngModel)]="local_data.orderAddress.email">
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <input placeholder="{{ 'neworderdialog.phone' | transloco }}" matInput name="orderAddressPhone" [(ngModel)]="local_data.orderAddress.phone">
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <input placeholder="{{ 'neworderdialog.contact' | transloco }}" matInput name="orderAddressContactName"
                 [(ngModel)]="local_data.orderAddress.contactName">
        </mat-form-field>
        <mat-form-field class="dataFieldMax">
          <textarea rows="4" placeholder="{{ 'neworderdialog.contacthint' | transloco }}" matInput name="orderAddressContactNote"
                    [(ngModel)]="local_data.orderAddress.contactNote"></textarea>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <ng-template #elseTemplate>
    <p>{{ 'neworderdialog.delete1' | transloco }} <b>{{local_data.event}}</b> {{ 'neworderdialog.delete2' | transloco }}</p>
  </ng-template>

  <div mat-dialog-actions class="actions">
    <button mat-button (click)="doAction()" [disabled]="orderForm.invalid" color="accent" mat-flat-button>{{ 'neworderdialog.ok' | transloco }}</button>
    <button mat-button (click)="closeDialog()">{{ 'neworderdialog.cancel' | transloco }}</button>
  </div>
</form>
