<div id="main" role="main">
  <h1>{{ 'customerorders.titel' | transloco }}</h1>
  <i>{{ 'customerorders.sub' | transloco }}</i>

  <h3>{{ 'customerorders.new' | transloco }}</h3>

  <mat-card class="infocard" *ngIf="CustomerInformation?.orderNote">
    <mat-card-content><span class="hint">{{ 'customerorders.hint' | transloco }} </span>{{CustomerInformation?.orderNote}}</mat-card-content>
  </mat-card>
  <button (click)="openDialog('anlegen')" mat-btn color="accent" mat-raised-button  attr.aria-label="{{ 'customerorders.newcustomer' | transloco }}"><mat-icon>add</mat-icon> {{ 'customerorders.new' | transloco }}</button>

  <h3>{{ 'customerorders.orders' | transloco }}</h3>

  <div class="table-container">

    <mat-form-field *ngIf="OrderList">
      <input matInput (keyup)="applyFilterOpen($event)" placeholder="{{ 'customerorders.search' | transloco }}">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loadingBar" mode="query"></mat-progress-bar>
      <table *ngIf="OrderList" mat-table multiTemplateDataRows [dataSource]="OrderList">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Von Column -->
        <ng-container matColumnDef="von">
          <th mat-header-cell *matHeaderCellDef> {{ 'customerorders.from' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.deliveryDate | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <!-- Bis Column -->
        <ng-container matColumnDef="bis">
          <th mat-header-cell *matHeaderCellDef> {{ 'customerorders.to' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.pickupDate | date:'dd.MM.yyyy'}} </td>
        </ng-container>

        <!-- Veranstaltung Column -->
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef> {{ 'customerorders.event' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.eventName}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> {{ 'customerorders.status' | transloco }} </th>
          <td mat-cell *matCellDef="let orders">
            <span *ngIf="(orders.state != 'Waiting' && approver) || !approver">{{orders.state}}</span>
            <span style="color:#e40c7d;" *ngIf="orders.state == 'Waiting' && approver">{{orders.state}}</span>
          </td>
        </ng-container>

        <!-- OrderPrintout Column -->
        <ng-container matColumnDef="print">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> {{ 'customerorders.deliverynote' | transloco }} </th>
          <td mat-cell class="tableIcon" *matCellDef="let orders">
            <div class="deactivated" *ngIf="orders.state == 'Pending' || orders.state == 'Created' || orders.state == 'Ordered' || orders.state == 'Waiting'"><mat-icon>print</mat-icon></div>
            <div class="clickable activated" *ngIf="orders.state === 'Shipped' || orders.state === 'Returned'" [routerLink]="['/order-deliverynote', orders.id]"><mat-icon>print</mat-icon></div>
          </td><!-- deaktiviert -->
        </ng-container>

          <!-- Artikel Column -->
        <ng-container matColumnDef="articles">
          <th mat-header-cell *matHeaderCellDef> {{ 'customerorders.amountarticles' | transloco }} </th>
          <td mat-cell *matCellDef="let orders"> {{orders.articles.length}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let order" [attr.colspan]="displayedColumnsOrderList.length">
            <div class="order-detail"
                 [@detailExpand]="order == expandedElement ? 'expanded' : 'collapsed'">
              <h3>{{order.eventName}}</h3>
              <i>{{ 'customerorders.number' | transloco }} {{order.id}}</i>
              <div *ngIf="order.state == 'Waiting' && approver" style="position: absolute;top: 35px;right: 0px;">
                <button *ngIf="!orderApproved" (click)="orderApproval(order.id)" mat-btn color="accent" mat-raised-button  attr.aria-label="{{ 'customerorders.approvalButton' | transloco }}"><mat-icon>check</mat-icon> {{ 'customerorders.approvalButton' | transloco }}</button>
                <span *ngIf="orderApproved" style="color: #d90660;">
                  {{ 'customerorders.approvalHint' | transloco }}
                </span>
              </div>
              <div *ngIf="order.state == 'Created'" style="position: absolute;top: 35px;right: 0px;">
                <button (click)="openDetail(order.id)" mat-btn color="accent" mat-raised-button  attr.aria-label="{{ 'customerorders.editButton' | transloco }}"><mat-icon>edit</mat-icon> {{ 'customerorders.editButton' | transloco }}</button>
              </div>
              <p><b>{{order.deliveryDate | date:'dd.MM.yyyy'}}</b> - <b>{{order.pickupDate | date:'dd.MM.yyyy'}}</b></p>
              <p>{{ 'global.orderer' | transloco }} <b>{{order?.orderer}}</b></p>
              <p>
                {{ 'customerorders.delivery' | transloco }} <b>{{order.deliveryWindow}}</b><br>
                {{ 'customerorders.pickup' | transloco }} <b>{{order.pickupWindow}}</b>
              </p>

              <h4>{{ 'customerorders.address' | transloco }}</h4>
              <p>
                {{order.deliveryAddress?.company}}<br>
                {{order.deliveryAddress?.street}}<br>
                {{order.deliveryAddress?.zip}} {{order.deliveryAddress?.city}}<br>
                {{order.deliveryAddress?.country}}
              </p>
              <h4>{{ 'customerorders.contact' | transloco }}</h4>
              <p>
                {{order.deliveryAddress?.contactName}}<br>
                {{order.deliveryAddress?.phone}}
              </p>
              <ng-container *ngIf="OrderAddressMandatory == 'true'">
                <h4>{{ 'customerorders.orderaddress' | transloco }}</h4>
                <p>
                  {{order.orderAddress?.company}}<br>
                  {{order.orderAddress?.street}}<br>
                  {{order.orderAddress?.zip}} {{order.orderAddress?.city}}<br>
                  {{order.orderAddress?.country}}
                </p>
              </ng-container>

              <h4>{{ 'customerorders.article' | transloco }}</h4>
              <p *ngIf="!order.articles.length">{{ 'customerorders.noarticles' | transloco }}</p>
              <table *ngIf="order.articles.length" style="width: auto;">
                <thead>
                  <tr>
                    <th>{{ 'customerorders.articlename' | transloco }}</th><th>{{ 'customerorders.amount' | transloco }}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let article of order.articles">
                  <tr *ngIf="article.amount > 0">
                    <td>{{ article.name }}</td><td>{{ article.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsOrderList"></tr>
        <tr mat-row *matRowDef="let order; columns: displayedColumnsOrderList;"
            class="order-row"
            [class.expanded-row]="expandedElement === order"
            (click)="expandedElement = expandedElement === order ? null : order">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

      </table>

      <mat-paginator *ngIf="OrderList" [pageSizeOptions]="[5, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
