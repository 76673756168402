<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'background-image': bgImage}">

  <mat-card appearance="outlined" class="box">
    <mat-card-header>
      <mat-card-title>{{ 'login.title' | transloco }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()" *ngIf="!lostPasswordState">
        <div *ngIf="loginError">{{ 'login.Error-PW' | transloco }}</div>
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="{{ 'login.placeUser' | transloco }}" formControlName="username" />
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="!newPasswordRequired">
          <input type="password" matInput placeholder="{{ 'login.placePass' | transloco }}" formControlName="password" />
        </mat-form-field>
        <button *ngIf="!newPasswordRequired" type=submit mat-stroked-button color="accent"
          class="btn-block">{{ 'login.button' | transloco }}</button>
        <a *ngIf="!newPasswordRequired" (click)="lostPasswordState = 'username'">{{ 'login.forgotten' | transloco }}</a>
      </form>
      <form class="login-form" [formGroup]="lostPasswordForm" (ngSubmit)="lostPassword()" *ngIf="lostPasswordState">
        <mat-form-field class="full-width">
          <input type="text" matInput placeholder="{{ 'login.placeUser' | transloco }}" formControlName="username" />
        </mat-form-field>
        <span *ngIf="lostPasswordState === 'code'">{{ 'login.verify' | transloco }}</span>
        <mat-form-field class="full-width" *ngIf="lostPasswordState === 'code'">
          <input type="text" matInput placeholder="{{ 'login.placeCode' | transloco }}" formControlName="code" />
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="lostPasswordState === 'code'">
          <input type="password" matInput placeholder="{{ 'login.placeNewPass' | transloco }}" formControlName="newpassword" minlength="8" required/>
          <mat-error *ngIf="lostPasswordForm.controls['newpassword'].invalid">{{ 'login.newPassHint' | transloco }}</mat-error>
        </mat-form-field>
        <button *ngIf="lostPasswordState === 'username'" type=submit mat-stroked-button color="accent" class="btn-block">{{ 'login.newPassOrder' | transloco }}</button>
        <button *ngIf="lostPasswordState === 'code'" type=submit mat-stroked-button color="accent" class="btn-block" [disabled]="lostPasswordForm.controls['newpassword'].invalid">{{ 'login.newPassButton' | transloco }}</button>
      </form>
      <br />
      <form class="login-form" [formGroup]="newPasswordForm" (ngSubmit)="newPasswordSet()" *ngIf="newPasswordRequired">
        {{ 'login.newPassTitle' | transloco }}
        <mat-form-field class="full-width">
          <input type="password" matInput placeholder="{{ 'login.placeNewPass' | transloco }}" formControlName="newpassword" minlength="8" required/>
          <mat-error *ngIf="newPasswordForm.controls['newpassword'].invalid">{{ 'login.newPassHint' | transloco }}</mat-error>
        </mat-form-field>
        <button type=submit mat-stroked-button color="accent" class="btn-block" [disabled]="newPasswordForm.controls['newpassword'].invalid">{{ 'login.newPassButton' | transloco }}</button>
      </form>
    </mat-card-content>
  </mat-card>

</div>
